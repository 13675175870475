
// // Import necessary components
// import React, { useState } from 'react';
// import './JodiPairPage.css'; // Import your CSS file if needed

// const JodiPairPage = () => {
//   const jodiPairs = [
//     {
//         date: 'Date',
//         values: [
//             ['12', '12', '1998'],
//             ['12', '12', '2002'],
//             ['12', '01', '2020'], // Replace with actual values
//             ['12', '12', '2021'],
//         ]
//     },
//     {
//         date: 'New Madhuri Night',
//         values: [
//             ['12', '1232', '237'],
//             ['12', '1232', '237'],
//             ['12', '1232', '237'], // Replace with actual values
//             ['12', '1232', '237'],
//         ]
//     },
//     {
//         date: 'New Madhuri Night',
//         values: [
//             ['12', '1232', '237'],
//             ['12', '1232', '237'],
//             ['12', '1232', '237'], // Replace with actual values
//             ['12', '1232', '237'],
//         ]
//     },
//     {
//         date: 'Milan Day1',
//         values: [
//             ['12', '1232', '237'],
//             ['12', '1232', '237'],
//             ['12', '1232', '237'], // Replace with actual values
//             ['12', '1232', '237'],
//         ]
//     },
//     {
//         date: 'Milan Day2',
//         values: [
//             ['12', '1232', '237'],
//             ['12', '1232', '237'],
//             ['12', '1232', '237'], // Replace with actual values
//             ['12', '1232', '237'],
//         ]
//     },
//     {
//         date: 'Milan Day3',
//         values: [
//             ['12', '1232', '237'],
//             ['12', '1232', '237'],
//             ['12', '1232', '237'], // Replace with actual values
//             ['12', '1232', '237'],
//         ]
//     },
//     // Add more data objects here
// ];

//     const dateColors = ['#b8e060', '#b8e060', '#b8e060']; // Colors for dates
//     const valueColors = ['#19701a', '#3b805f', '#33ffce']; // Colors for values

//     const [updatedValue, setUpdatedValue] = useState('');

//     const [updatedData, setUpdatedData] = useState([]);

//     const handleUpdate = (index, updatedValues) => {
//         const updatedJodiPairs = [...updatedData];
//         updatedJodiPairs[index].values = updatedValues;
//         setUpdatedData(updatedJodiPairs);

//         // Send updatedData to the backend API
//         console.log('Updated Data:', updatedData);
//     };
//     const handleDelete = (index) => {
//         // Implement the logic for deleting the data
//         console.log(`Delete clicked for index: ${index}`);
//     };

//     const handleInputChange = (event) => {
//         setUpdatedValue(event.target.value);
//     };

//     return (
//         <div className="jodi-pair-page">
//             <h1>Dynamic Header - Jodi Pair Page</h1>
//             <table className="jodi-pair-table">
//                 <thead>
//                     <tr>
//                         {jodiPairs.map((pair, index) => (
//                             <th style={{ backgroundColor: dateColors[index % dateColors.length] }} key={index}>
//                                 {pair.date}
//                             </th>
//                         ))}
//                     </tr>
//                 </thead>
//                 <tbody>
//                     {[0, 1, 2].map((idx) => (
//                         <tr key={idx}>
//                             {jodiPairs.map((pair, index) => (
//                                 <td style={{ backgroundColor: valueColors[index % valueColors.length] }} key={pair.date}>
//                                     {pair.values[idx].join(' ')}
//                                     <div>
//                                         <input
//                                             type="text"
//                                             value={updatedValue}
//                                             onChange={handleInputChange}
//                                             placeholder="Enter updated value"
//                                         />
//                                         <button onClick={() => handleUpdate(idx)} className="update-button">
//                                             Update
//                                         </button>
//                                         <button onClick={() => handleDelete(idx)} className="delete-button">
//                                             Delete
//                                         </button>
//                                     </div>
//                                 </td>
//                             ))}
//                         </tr>
//                     ))}
//                 </tbody>
//             </table>
//         </div>
//     );
// };

// export default JodiPairPage;









import React, { useEffect, useState } from 'react';
import './Details.css';

const JodiPairPage = () => {

    const apiUrl = "https://satta-backend.vercel.app";
  
//   const jodiPairs = [
//     {
//         date: 'Date',
//         values: [
//             ['12', '12', '1998'],
//             ['12', '12', '1999'],
//             ['12', '01', '2020'], // Replace with actual values
//             ['12', '12', '2021'],
//             ['12', '12', '2021'],
//         ]
//     },
//     {
//         date: 'New Madhuri Night',
//         values: [
//             ['12', '1232', '237'],
//             ['12', '1232', '238'],
//             ['12', '1232', '239'], // Replace with actual values
//             ['12', '1232', '240'],
//             ['12', '12', '2021'],
//         ]
//     },
//     {
//         date: 'New Madhuri Night',
//         values: [
//             ['12', '1232', '241'],
//             ['12', '1232', '242'],
//             ['12', '1232', '243'], // Replace with actual values
//             ['12', '1232', '244'],
//             ['12', '12', '2021'],
//         ]
//     },
//     {
//         date: 'Milan Day1',
//         values: [
//             ['12', '1232', '245'],
//             ['12', '1232', '246'],
//             ['12', '1232', '247'], // Replace with actual values
//             ['12', '1232', '248'],
//             ['12', '12', '2021'],
//         ]
//     },
//     {
//         date: 'Milan Day2',
//         values: [
//             ['12', '1232', '239'],
//             ['12', '1232', '250'],
//             ['12', '1232', '251'], // Replace with actual values
//             ['12', '1232', '252'],
//             ['12', '12', '2021'],
//         ]
//     },
//     {
//         date: 'Milan Day3',
//         values: [
//             ['12', '1232', '253'],
//             ['12', '1232', '254'],
//             ['12', '1232', '255'], // Replace with actual values
//             ['12', '1232', '256'],
//             ['12', '12', '2021'],
//         ]
//     },
//     // Add more data objects here
// ];

    const dateColors = ['#b8e060', '#b8e060', '#b8e060']; // Colors for dates
    const valueColors = ['#19701a', '#3b805f', '#33ffce']; // Colors for values

    const [updatedValues,setUpdatedValues] = useState('');
    // const [updatedValues, setUpdatedValues] = useState({});

    const [trackIndex,setTrackIndex] = useState('');
    const [trackOuterIndex,setTrackOuterIndex] = useState('');
    const [trackIndividualIndex,setTrackIndividualIndex] = useState('');
    const [pairDateKey,setPairDateKey] = useState('');


    const handleInputChange = (event) => {
      setUpdatedValues(event)
    };


    // const [updatedValues, setUpdatedValues] = useState(jodiPairs);

     //get data from backend api 

     const [jodiPairs,setjodiPairs] =useState([])
     const [isLoading, setIsLoading] = useState(true);

     const GettingAllData = async () => {
        const url = `${apiUrl}/api/getall`;
        const options = {
          method: 'get',
          headers: {
            'Content-Type': 'application/json',
          },
        };
      
        try {
          const response = await fetch(url, options);
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          const responseData = await response.json();
          setjodiPairs(responseData)
          setIsLoading(false)
          console.log('data getting from backend:', responseData);
        } catch (error) {
          console.error('Error: in data getting ', error.message);
        }
      };
    
     useEffect( () => {
        GettingAllData();
     },[])
  

    const handleDelete = (id) => {
        // Send the ID to the backend API for deletion
        console.log('Delete clicked for ID:', id);

        console.log('id',id)
        console.log('Updated Values:', updatedValues);
        console.log('outer index i ',trackIndex)
        console.log('inner index',trackOuterIndex)
        console.log('track individual index j ',trackIndividualIndex)
        console.log('pair date key ',pairDateKey)
   
        
        
        let dataStore 
        for (let i = 0; i < jodiPairs.length; i++) {
            if (jodiPairs[i].date === pairDateKey) {
                jodiPairs[i].values[trackIndex][trackIndividualIndex] = updatedValues;
                console.log(jodiPairs[i]); // Log the updated object
                // Remove empty arrays
                jodiPairs[i].values = jodiPairs[i].values.filter((value) => value.some(Boolean));
                dataStore = jodiPairs[i];
                console.log('complete data ');
                console.log(dataStore);
                sendUpdatedDataToBackend(jodiPairs[i],id)
                break; // If the target is found, no need to iterate further
            }
        }
        
        


    };

 

    const sendUpdatedDataToBackend = async (dataStore, id) => {
      try {
          console.log('data and id')
          console.log(dataStore)
          console.log(id)
          let result = await fetch(`${apiUrl}/api/update/data/${id}`, {
              method: 'put',
              headers: {
                  'Content-Type': 'application/json' // Corrected the header name to 'Content-Type'
              },
              body: JSON.stringify({
                  dataStore
              })
          });
  
          if (result.ok) {
              result = await result.json();
              alert('updated successfully')
              GettingAllData();
              console.log('updated successfully');
              console.log(result)
          } else {
              console.log(result)
              console.log('error in data updateion ')
          }
      } catch (error) {
          console.log('error in sending update', error.message);
      }
  }
  


    const handleUpdate = (id) => {
      // Send the ID and updated values to the backend API
      console.log('id',id)
      console.log('Updated Values:', updatedValues);
      console.log('outer index i ',trackIndex)
      console.log('inner index',trackOuterIndex)
      console.log('track individual index j ',trackIndividualIndex)
      console.log('pair date key ',pairDateKey)
 
      //  let onePair = []
            
      const newValue = '24';
      const targetDate = 'Date'; // Target date to identify the correct 0,0 position
      
      let dataStore 
      for (let i = 0; i < jodiPairs.length; i++) {
          if (jodiPairs[i].date === pairDateKey) {
              jodiPairs[i].values[trackIndex][trackIndividualIndex] = updatedValues;
              console.log(jodiPairs[i]); // Log the updated object
              dataStore = jodiPairs[i];
              sendUpdatedDataToBackend(jodiPairs[i],id)
              break; // If the target is found, no need to iterate further
          }
      }


    
      

  };



  const DeleteEntryToBackend = async (id) => {
    try {
        console.log('id is here ',id)
        let result = await fetch(`${apiUrl}/api/delete/data/${id}`, {
            method: 'delete',
            headers: {
                'Content-Type': 'application/json' // Corrected the header name to 'Content-Type'
            },
        });

        if (result.ok) {
            result = await result.json();
            alert('Deleted successfully')
            GettingAllData();
            console.log('deleted successfully');
            console.log(result)
        } else {
            console.log(result)
            console.log('error in data updateion ')
        }
    } catch (error) {
        console.log('error in sending update', error.message);
    }
}



const handleDeleteFullEntry = (id) => {
    if (window.confirm('Are you sure you want to delete?')) {
      // Call your delete function or API endpoint here
    //   this.deleteFunction();
    DeleteEntryToBackend(id)
    } else {
      // If the user clicks "Cancel", handle it here
      removeAlert();
    }
  };


 const  removeAlert = () => {
    // Remove any alert if the user cancels the delete action
    let alerts = document.getElementsByClassName('alert');
    while (alerts[0]) {
      alerts[0].parentNode.removeChild(alerts[0]);
    }
  };




    return (
        <div className="jodi-pair-page">
        <h1>Dynamic Header - Jodi Pair Page</h1>
        {isLoading ? (
        <div className="loader-container">
          <div className="loader"></div>
          <p>Loading...</p>
        </div>
      ) : (
         <table className="jodi-pair-table">
            <thead>
                <tr>
                    {jodiPairs.map((pair, index) => (
                        <th  style={{ backgroundColor: dateColors[index % dateColors.length] }} key={pair._id}>
                        <th style={{ backgroundColor: dateColors[index % dateColors.length] }} key={pair._id}>
                            {pair.date}
                        </th>
                        <button 
                        onClick={ () => handleDeleteFullEntry(pair._id)}
                        // style={{ backgroundColor: dateColors[index % dateColors.length] }} key={pair._id}
                        >
                            delete
                            </button>
                        </th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {jodiPairs.length > 0 &&
                    Array.from({ length: Math.max(...jodiPairs.map((pair) => pair.values.length)) }).map((_, idx) => (
                        <tr key={idx}>
                            {jodiPairs.map((pair, index) => (
                                <td style={{ backgroundColor: valueColors[index % valueColors.length] }} key={pair._id}>
                                    {pair.values && pair.values.length > idx && pair.values[idx] ? (
                                        pair.values[idx].map((value, valueIdx) => (
                                            <div key={valueIdx}>
                                                <input
                                                    type="text"
                                                    name={valueIdx.toString()}
                                                    value={ 
                                                        updatedValues && pairDateKey === pair.date
                                                         && trackIndex === idx && trackOuterIndex === index
                                                         && trackIndividualIndex === valueIdx 
                                                          ? updatedValues : value}
    
                                                    // onChange={(event) => handleInputChange(event, pair._id, idx)}
                                                    onChange={(event) => {
                                                        handleInputChange(event.target.value);
                                                        setTrackIndex(idx);
                                                        setPairDateKey(pair.date);
                                                        setTrackOuterIndex(index);
                                                        setTrackIndividualIndex(valueIdx);
                                                      
                                                        console.log('outer index', index);
                                                        console.log('inner index', idx);
                                                    }}
                                                    placeholder="Enter updated value"
                                                />
                                                <button onClick={() => handleUpdate(pair._id)} className="update-button">
                                                    Update
                                                </button>
                                                <button 
                                                onClick={() => { 
                                                    handleDelete(pair._id) 
                                                    setTrackIndex(idx);
                                                    setPairDateKey(pair.date);
                                                    setTrackOuterIndex(index);
                                                    setTrackIndividualIndex(valueIdx);
    
                                                    }} className="delete-button">
                                                    Delete
                                                </button>
                                            </div>
                                        ))
                                    ) : (
                                        <div>No values available</div>
                                    )}
                                </td>
                            ))}
                        </tr>
                    ))}
            </tbody>
        </table>
      )}
    </div>
    
    );
};

export default JodiPairPage;

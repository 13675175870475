import React from 'react';
import './t3.css'
import Contact from '../Contact';
import HomeOpen from '../HomeOpen';
const HorizontalHeadingComponent = () => {
  const headings = [
    'Night Queen', 'Singapore', 'Kuber Day', 'New Malava', 'Sapna', 'Milan Day', 'New Ratan Day',
    'Kalyan', 'Kuber Evening', 'New Kalyan', 'Kuber Night', 'Sapna Night', 'Milan Night',
    'New Ratan Night', '(New) Mumbai', 'Bangalore Day', 'Morning Kalyan', 'Baba Day', 'Baba Night',
    'New Mumbai Day', 'Polo Day', 'Polo Night', 'Rashifal', 'Shalimar', 'New Rajdhani Day',
    'New Rajdhani Night', 'Time', 'Kolkata Day', 'Kolkata Night', 'Hyderabad', 'Rajdhani Day',
    'Bilaspur', 'Rajdhani Night', 'Apna Day', 'Hyderabad Night', 'Kuber Morning', 'American Night',
    'Dubai Express', 'Singapore Night', 'Mumbai', 'Dharmatma', 'Milan Super', 'Shurbhi Morning',
    'Shurbhi Day', 'Shurbhi Night', 'Main Ratan', 'Pushpa', 'Rajdhani Evening', 'Padmavati Day',
    'Padmavati Night', 'Padmavati Star', 'New Sreedevi Day', 'New Supreme Day', 'New Sreedevi Night',
    'New Madhuri Day', 'Time Night', 'New Madhuri Night'
  ];
  return (
    <>
    
    <div className="image-container">
              {/* <img src="https://giffiles.alphacoders.com/142/14261.gif"/> */}
              <img src="https://giffiles.alphacoders.com/142/14261.gif"/>
              <img src="https://i.pinimg.com/736x/c6/79/ab/c679ab331c697f0c56f66b1dc92c2bfc.jpg" alt="Your Image" />
              <img src="https://giffiles.alphacoders.com/142/14261.gif"/>
              {/* <img src="https://giffiles.alphacoders.com/142/14261.gif"/> */}

            </div>
    <div className="horizontal-heading-component">


     <HomeOpen/>
      
      {/* {headings.map((heading, index) => (
        <React.Fragment key={index}>
          <div className="heading-container">
            <h2>{heading}</h2>
            <div className="vertical-subheadings">
              <p>{Math.floor(Math.random() * 100)}</p>
              <p>{Math.floor(Math.random() * 100)}</p>
              <p>{Math.floor(Math.random() * 100)}</p>
            </div>
          </div>
          {/* {index === Math.floor(headings.length / 4) && (
            
          )} */}
        {/* </React.Fragment> */}
      {/* ))} */} 

    </div>
    </>
  );
};

export default HorizontalHeadingComponent;
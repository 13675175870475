// import React, { useEffect, useState } from 'react';
// import './Contact.css'; // Import the CSS file for styling

// const Contact = () => {

//   const apiUrl = process.env.REACT_APP_API_URL;

//   const initialData = [
//     {
//       date: "Milan Day",
//       values: [
//         ["12", "12", "2040"],
//       ],
//     },
//     {
//       date: "Some Other Day",
//       values: [
//         ["12", "12", "2033"],
//       ],
//     },
//   ];

//    const [isLoading, setIsLoading] = useState(true);
//    const [data, setData] = useState([]);
   
//    const GettingAllData = async () => {
//     const url = `${apiUrl}/api/home/get`;
//     const options = {
//       method: "get",
//       headers: {
//         "Content-Type": "application/json",
//       },
//     };

//     try {
//       const response = await fetch(url, options);
//       if (!response.ok) {
//         console.log('error in getting data')
//         throw new Error(`HTTP error! Status: ${response.status}`);
//       }
//       else if(response.ok){
//       const responseData = await response.json();
//       setData(responseData)
//       console.log('ok respnse ')      
//       setIsLoading(false);
//       console.log("data getting from backend:", responseData);
//     }
//     } catch (error) {
//       console.error("Error: in data getting ", error.message);
//     }
//   };

//   useEffect(() => {
//     GettingAllData();
//   }, []);




//   console.log('update data ',data)

//   const colors = ['#ff9999', '#66b3ff', '#99ff99', '#ffcc99', '#c299ff', '#ffeb99']; // Add colors as needed

//   const handleEdit = (index, newValue, position) => {
//     const updatedData = [...data];
//     updatedData[index].values[position] = newValue;
//     setData(updatedData);
//     console.log('update data ')
//     console.log(updatedData)
//     console.log(updatedData[index]);
//   };






  
//   // return (
//   //   <div className="container">
//   //     {isLoading ? (
//   //       <div className="loader-container">
//   //         <div className="loader"></div>
//   //         <p>Loading...</p>
//   //       </div>
//   //     ) : (
//   //       <>
//   //         {data && data.length > 0 ? (
//   //           <div className="data-container">
//   //             {data.map((item, index) => (
//   //               <div className="last-array" key={index} style={{ backgroundColor: colors[index] }}>
//   //                 <div className="date">{item.date}</div>
//   //                 <div className="values">
//   //                   {item.values[0].map((value, pos) => (
//   //                     <input
//   //                       key={pos}
//   //                       value={value}
//   //                       onChange={(e) => handleEdit(index, e.target.value, pos)}
//   //                       style={{ backgroundColor: colors[index] }}
//   //                     />
//   //                   ))}
//   //                 </div>
//   //               </div>
//   //             ))}
//   //           </div>
//   //         ) : (
//   //           <p>No data to display.</p>
//   //         )}
//   //       </>
//   //     )}
//   //   </div>
//   // );
  



//   return (
//     <div className="container">
//        {isLoading ? (
//          <div className="loader-container">
//          <div className="loader"></div>
//          <p>Loading...</p>
//        </div>
//       ) : (
//       <div className="container">
//       { data && data.length ?
//          data.map((item, index) => (
//         <div className="last-array" key={index} style={{ backgroundColor: colors[index] }}>
//           <div className="date">{item.date}</div>
//           <div className="values">
//             {item.values.map((value, pos) => (
//               <input
//                 key={pos}
//                 value={value}
//                 onChange={(e) => handleEdit(index, e.target.value, pos)}
//                 style={{ backgroundColor: colors[index] }}
//               />
//             ))}
//           </div>
//         </div>
//       )) : null 
//     }
//       </div>
//       )}
//     </div>
//   );

// };

// export default Contact;










import React, { useState, useEffect } from 'react';
import './Contact.css'; // Import the CSS file for styling

const Contact = () => {

  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);

  const apiUrl = "https://satta-backend.vercel.app";


  const colors = ['#ff9999', '#66b3ff', '#99ff99', '#ffcc99', '#c299ff', '#ffeb99', '#ffa07a', '#20b2aa', '#9370db', '#ff7f50'];



  const GettingAllData = async () => {
        const url = `${apiUrl}/api/home/get`;
        const options = {
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
        };
    
        try {
          const response = await fetch(url, options);
          if (!response.ok) {
            console.log('error in getting data')
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          else if(response.ok){
          const responseData = await response.json();
          setData(responseData)
          console.log('ok respnse ')      
          setIsLoading(false);
          console.log("data getting from backend:", responseData);
        }
        } catch (error) {
          console.error("Error: in data getting ", error.message);
        }
      };
    
      useEffect(() => {
        GettingAllData();
      }, []);



  const handleEdit = (index, innerIndex, event) => {
    const updatedData = [...data];
    updatedData[index].values[0][innerIndex] = event.target.value;
    console.log('update data ')
    console.log(updatedData[index])
    setData(updatedData);
    SendingSingleEntryToBackend(updatedData[index])
  };


const SendingSingleEntryToBackend = async (updatedData) => {
      const url = `${apiUrl}/api/home/data`;
      const options = {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body : JSON.stringify({
          updatedData 
        })
      };
  
      try {
        const response = await fetch(url, options);
        if (!response.ok) {
          console.log('error in getting data')
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        else if(response.ok){
        const responseData = await response.json();
        console.log("value updated:", responseData);
        alert(responseData.message)
      }
      } catch (error) {
        console.error("Error: in data getting ", error.message);
      }
    }


    //updating key value in this 
    const [newName,setNewName] =useState('');
    const [trackIndex,setTrackIndex] =useState('');
    
    const sendUpdatedDataToBackend = async (id) => {
      try {
          console.log('data and id')
          console.log(id);
          console.log(newName);
          
          let result = await fetch(`${apiUrl}/api/updatename/keydata/${id}`, {
              method: 'put',
              headers: {
                  'Content-Type': 'application/json' // Corrected the header name to 'Content-Type'
              },
              body: JSON.stringify({
                  newName
              })
          });
  
          if (result.ok) {
              result = await result.json();
              alert('updated successfully')
              GettingAllData();
              console.log('updated successfully');
              console.log(result)
          } else {
              console.log(result)
              console.log('error in data updateion ')
          }
      } catch (error) {
          console.log('error in sending update', error.message);
      }
  }
  
    


    // return (
    //   <div className="container">
    //   {isLoading ? (
    //     <div className="loader-container">
    //       <div className="loader"></div>
    //       <p>Loading...</p>
    //     </div>
    //   ) : (
    //     <div className="data-container">
    //       {data
    //         .filter((item) => item.date !== 'Date')
    //         .map((item, index) => (
    //           <div className="box" key={index} style={{ backgroundColor: colors[index % colors.length] }}>
    //              <div className="date">{item.date}</div>
    //             <div className="input-container">
    //               {item.values[0].map((value, innerIndex) => (
    //                 <input
    //                   key={innerIndex}
    //                   value={value}
    //                   onChange={(e) => handleEdit(index, innerIndex, e)}
    //                   className="input-box"
    //                   style={{ backgroundColor: colors[index % colors.length] }}
    //                 />
    //               ))}
    //             </div>
    //           </div>
    //         ))}
    //     </div>
    //   )}
    // </div>
    

    // )



   return (
    <div className="container">
      {isLoading ? (
        <div className="loader-container">
          <div className="loader"></div>
          <p>Loading...</p>
        </div>
      ) : (
        <div className="data-container">
          {data && data.length > 0 ? data.map((item, index) => (
            <div className="box" key={index} style={{ backgroundColor: colors[index % colors.length] }}>
              {/* <div className="date">{item.date}</div> */}
              <input
                    key={index}
                    value={  trackIndex === index ? newName : item.date}
                    onChange={(e) => 
                      {
                      setNewName(e.target.value)
                      setTrackIndex(index)
                    }}
                    className="input-box"
                    style={{ backgroundColor: colors[index % colors.length] }}
                  />
                  <button 
                   onClick={() => sendUpdatedDataToBackend(item._id)}>Update</button>
              <div className="input-container">
                {item.values[0] && item.values[0].map((value, innerIndex) => (
                  <input
                    key={innerIndex}
                    value={value}
                    onChange={(e) => handleEdit(index, innerIndex, e)}
                    className="input-box"
                    style={{ backgroundColor: colors[index % colors.length] }}
                  />
                ))}
              </div>
            </div>
          )) : null }
        </div>
      )}
    </div>
  );
};

export default Contact;

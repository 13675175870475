
import React from 'react';
import Frame from './HomePage/frame'
import Clock from './HomePage/Clock'
import MarqueLine from './HomePage/3marqueLine';
import HorizontalHeadingComponent from './HomePage/HorizontalHeadingComponent'

const Home = () => {
  
  return (
      <div> 

        {/* <Frame/> */}
      {/* <TableCellWithButtons /> */}
      <Clock/>
      <MarqueLine/>
      <HorizontalHeadingComponent />
        
      </div>

  )
};

export default Home;

import './mainTitleFrame.css'
export default function Frame () {
  return (
    <>
     <nav>
      <h1 className="header-title">Jaymatajisattamatka.com</h1>
    </nav>
    </>
  )
}



import React from "react";
import './MarqueeLine.css';

const MarqueLine = () => {
  return (
    <div className="marquee-container">
      <marquee behavior="scroll" direction="left" className="marquee">
      JAYMATAJISATTMATKA.COM  Online jaymataji day play 8327701242
      </marquee>

      <div className="buttons-container">
        {/* <button className="register-button">Register</button> */}
        <span className="info-text">
          Sabhi Game ki Booking Phone pe liya jayega. e-mail :
          jaymatadi@gmail.com
        </span>
        
        {/* <div className="login-form">
          <input type="text" placeholder="username" />
          <input type="password" placeholder="password" />
          <button>Login</button>
        </div> */}
      </div>
    </div>
  );
};

export default MarqueLine;





import React, { useEffect, useState } from "react";
import './JodiPairPage.css'; // Import your CSS file if needed

const JodiPairPage = () => {

  const apiUrl = "https://satta-backend.vercel.app";
  console.log('api url',apiUrl)

  const [jodiPairs, setjodiPairs] = useState([]);
  const [isLoading, setIsLoading] = useState(true);


  const GettingAllData = async () => {
    const url = `${apiUrl}/api/getall`;
    const options = {
      method: "get",
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      const response = await fetch(url, options);
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const responseData = await response.json();
      setjodiPairs(responseData);
      setIsLoading(false);
      console.log("data getting from backend:", responseData);
    } catch (error) {
      console.error("Error: in data getting ", error.message);
    }
  };

  useEffect(() => {
    GettingAllData();
  }, []);

  const dateColors = ['#ff9999', '#66b3ff', '#99ff99', '#ffcc99', '#c299ff', '#ffeb99', '#ffa07a', '#20b2aa', '#9370db', '#ff7f50'];


  // const dateColors = ["#6e6522", "#6e6522", "#6e6522"]; // Colors for dates
  // const valueColors = ["#19701a", "#3b805f", "#477994"]; // Colors for values

  const valueColors = ['#ff9999', '#66b3ff', '#99ff99', '#ffcc99', '#c299ff', '#ffeb99', '#ffa07a', '#20b2aa', '#9370db', '#ff7f50'];



  return (
    <div className="jodi-pair-page">
      {/* <h1>Dynamic Header - Jodi Pair Page</h1> */}
      {isLoading ? (
         <div className="loader-container">
         <div className="loader"></div>
         <p>Loading...</p>
       </div>
      ) : (
      <table className="jodi-pair-table">
        <thead>
          <tr>
            {jodiPairs.map((pair, index) => (
              <th
                style={{
                  backgroundColor: dateColors[index % dateColors.length],
                  padding: "25px",
                  whiteSpace: "nowrap", // Ensures text doesn't wrap
                  overflow: "hidden", // Handles text overflow
                  textOverflow: "ellipsis",
                  color:'black'
                }}
                key={pair._id}
              >
                {pair.date}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {jodiPairs.length > 0 &&
            Array.from({
              length: Math.max(...jodiPairs.map((pair) => pair.values.length)),
            }).map((_, idx) => (
              <tr key={idx}>
                {jodiPairs.map((pair, index) => (
                  <td
                    style={{
                      backgroundColor: valueColors[index % valueColors.length],
                      whiteSpace: "nowrap", // Ensures text doesn't wrap
                      overflow: "hidden", // Handles text overflow
                      textOverflow: "ellipsis", // Adds ellipsis if text overflows
                      color:'black'
                    }}
                    key={pair._id + idx}
                  >
                    {pair.values[idx] && pair.values[idx].length === 3
                      ? pair.date === "Date"
                        ? pair.values[idx].join("-")
                        : pair.values[idx].join(" ")
                      : null}
                  </td>
                ))}
              </tr>
            ))}
        </tbody>
        <tr>
            {jodiPairs.map((pair, index) => (
              <th
                style={{
                  backgroundColor: dateColors[index % dateColors.length],
                  padding: "25px",
                  whiteSpace: "nowrap", // Ensures text doesn't wrap
                  overflow: "hidden", // Handles text overflow
                  textOverflow: "ellipsis",
                  color:'black'
                }}
                key={pair._id}
              >
                {pair.date}
              </th>
            ))}
          </tr>
      </table>
      )}
    </div>
  );


};

export default JodiPairPage;




import React, { useEffect, useState } from 'react';
// import { BrowserRouter as Router, Route, Routes, Link} from 'react-router-dom';

import { HashRouter as Router, Route, Routes, Link} from 'react-router-dom';
import Home from './components/Home';
import Details from './components/Details';
import Contact from './components/Contact';
import NotFound from './components/NotFound';
import './App.css';
import JodiPairPage from './components/JodiPair';
import CreateEntry from './components/CreateEntry';

import Frame  from './components/HomePage/frame';
import MarqueLine from './components/HomePage/3marqueLine';


// App component
function App() {

  const [isAdminLoggedIn, setIsAdminLoggedIn] = useState(false);

  const apiUrl = "https://satta-backend.vercel.app";


  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [usernameError, setUsernameError] = useState('');
  const [passwordError, setPasswordError] = useState('');

  // const navigate = useNavigate(); // New line to add useNavigate hook


  useEffect(() => {
    const storedUsername = localStorage.getItem('username');
    if (storedUsername) {
      setUsername(storedUsername);
      setIsAdminLoggedIn(true);
    }
    // You can similarly handle password if needed
  }, []);

  //braod cast login to detect all tab in synchronized condition
  
  const broadcastLoggedIn = (isLoggedIn) => {
    const broadcastChannel = new BroadcastChannel('login_channel');
    broadcastChannel.postMessage({ loggedIn: isLoggedIn });
  };

  useEffect(() => {
    const broadcastChannel = new BroadcastChannel('login_channel');
    broadcastChannel.onmessage = (event) => {
      const { loggedIn } = event.data;
      setIsAdminLoggedIn(loggedIn);
    };
  }, []);


  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
    setUsernameError('');
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    setPasswordError('');
  };

  const handleLogin = async () => {
    if (!username) {
      setUsernameError('Username is required.');
      return
    }
    if (!password) {
      setPasswordError('Password is required.');
      return
    }
    if (username && password) {
      // Logic for handling login  
      console.log('coming here ')
        try {
            let result = await fetch(`${apiUrl}/api/admin/login`,{
                method: 'post',
                headers: {
                    'Content-Type': 'application/json' 
                },
                body: JSON.stringify({
                   username : username,
                   password : password
                })
            });
    
            if (result.ok) {
                result = await result.json();
                alert(result.message)
                console.log(result);
                localStorage.setItem('username', username);
                setIsAdminLoggedIn(true)
                broadcastLoggedIn(true);
                window.location.href = '/';
            } else if(result.status === 404) {
                result = await result.json();
                alert(result.message)
            } else if (result.status === 401){
                result = await result.json();
                alert(result.message)
            }

        } catch (error) {
            console.log('error in sending update', error.message);
        }
    }
  };

  const loginContainerStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  };


  const handleLogout = () => {
    localStorage.removeItem('username');
    setIsAdminLoggedIn(false);
    broadcastLoggedIn(false);
  };


  //for password hide and show 
  
  const [passwordVisible, setPasswordVisible] = useState(false);

  const handleTogglePasswordVisibility = () => {
    setPasswordVisible((prev) => !prev);
  };

  // JSX
  return (
    <>
      <Frame/>
    <Router>
      <div className="App">
          {/* ... (existing code for navigation) */}
          <nav
          className="header"
          // style={{
          //   display: 'flex',
          //   flexDirection: 'row',
          //   justifyContent: 'space-around',
          //   alignItems: 'center',
          // }}
        >
          <div>
            <ul 
            // style={{ listStyleType: 'none', display: 'flex', padding: 0 }}
            >
              <li 
              // style={{ margin: '0 10px'}}
              >
                <Link to="/" 
                className="nav-link home-link"
                id="home-link"
                >
                  Home
                </Link>
              </li>
            
              <li 
              // style={{ margin: '0 10px' }}
              >
                <Link to="/jodi"
                 className="nav-link jodi-link"
                 id="home-link"
                 >
                  Jodi Pair
                </Link>
              </li>
              {isAdminLoggedIn && (
                <>
                  <li
                  //  style={{ margin: '0 10px' }}
                   >
                    <Link to="/create" 
                    className="nav-link create-link"
                    id="home-link"
                    >
                      Create
                    </Link>
                  </li>
                  <li 
                  // style={{ margin: '0 10px' }}
                  >
                    <Link to="/details" 
                    className="nav-link details-link"
                    id="home-link"
                    >
                      Details
                    </Link>
                  </li>
                  <li 
                  // style={{ margin: '0 10px' }}
                  >
                <Link to="/contact" 
                className="nav-link contact-link"
                id="home-link"
                >
                  Home update
                </Link>
              </li>
                </>
              )}
            </ul>
            {/* <MarqueLine/> */}
          </div>
          <div>
          {isAdminLoggedIn ? (
        <button
        className="nav-link contact-link"
          onClick={handleLogout}
        >
          Logout
        </button>
      ) : (
        <Link to="/login/user" className="nav-link contact-link">
          Admin Login
        </Link>
      )}
          </div>
        </nav>
             {/* closed nav links */}
        <Routes>
          {/* ... (existing code for other routes) */}
          <Route path="/" element={<Home />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/jodi" element={<JodiPairPage />} />
        
          {isAdminLoggedIn && (
            <>
              <Route path="/create" element={<CreateEntry />} />
              <Route path="/details" element={<Details />} />
            </>
          )}
          <Route path="*" element={<NotFound />} />
          <Route
            path="/login/user"
            element={
              <div style={loginContainerStyle} className="login-container">
      <h2 style={{ fontSize: '24px', color: 'dodgerblue' }}>Login </h2>
     
     
      <div className="input-container">
        <input
          type="text"
          placeholder="Username"
          value={username}
          onChange={handleUsernameChange}
          className={usernameError ? 'error' : ''}
          style={{
            padding: '10px',
            border: usernameError ? '1px solid red' : '1px solid #ddd',
            borderRadius: '5px',
            marginBottom: '10px',
            paddingRight: '30px', // add space for the icon
          }}
        />
         {usernameError && <p style={{ color: 'red' }}>{usernameError}</p>}
      </div>
      {/* <div className="input-container"> */}
      <div className="input-container" style={{ position: 'relative' }}>
        <input
          type={passwordVisible ? 'text' : 'password'}
          placeholder="Password"
          value={password}
          onChange={handlePasswordChange}
          className={passwordError ? 'error' : ''}
          style={{
            padding: '10px',
            border: passwordError ? '1px solid red' : '1px solid #ddd',
            borderRadius: '5px',
            marginBottom: '10px',
            paddingRight: '30px', // add space for the icon
          }}
        />
        <span
          onClick={handleTogglePasswordVisibility}
          style={{
            position: 'absolute',
            right: '10px',
            top: '40%',
            transform: 'translateY(-50%)',
            cursor: 'pointer',
            userSelect: 'none', // ensure the icon is not selectable
          }}
        >
          {passwordVisible ? '🙈' : '👁️'}
        </span>
      </div>
         {passwordError && <p style={{ color: 'red' }}>{passwordError}</p>}
      {/* </div> */}
      <button
        // onClick={handleLogin}
        onClick={() => {
          handleLogin();
        }}
        style={{
          padding: '10px 20px',
          marginTop: '20px',
          backgroundColor: 'dodgerblue',
          color: 'white',
          border: 'none',
          borderRadius: '5px',
          cursor: 'pointer',
        }}
      >
        Login
      </button>
    </div>
            }
          />
        </Routes>
      </div>
    </Router>
   </>
  );
}

export default App;








import React, { useState, useEffect } from 'react';
import './Contact.css'; // Import the CSS file for styling

const HomeOpen = () => {

  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);

  const apiUrl = "https://satta-backend.vercel.app";


  const colors = ['#ff9999', '#66b3ff', '#99ff99', '#ffcc99', '#c299ff', '#ffeb99', '#ffa07a', '#20b2aa', '#9370db', '#ff7f50'];



  const GettingAllData = async () => {
        const url = `${apiUrl}/api/home/get`;
        const options = {
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
        };
    
        try {
          const response = await fetch(url, options);
          if (!response.ok) {
            console.log('error in getting data')
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          else if(response.ok){
          const responseData = await response.json();
          setData(responseData)
          console.log('ok respnse ')      
          setIsLoading(false);
          console.log("data getting from backend:", responseData);
        }
        } catch (error) {
          console.error("Error: in data getting ", error.message);
        }
      };
    
      useEffect(() => {
        GettingAllData();
      }, []);



   return (
    <div className="container">
      {isLoading ? (
        <div className="loader-container">
          <div className="loader"></div>
          <p>Loading...</p>
        </div>
      ) : (
        <div className="data-container">
          {data && data.length> 0 ? data.map((item, index) => (
            <div className="box" key={index} style={{ backgroundColor: colors[index % colors.length] }}>
              <div className="date">{item.date}</div>
              <div className="input-container">
                {item.values[0] && item.values[0].map((value, innerIndex) => (
                  <input
                    key={innerIndex}
                    value={value}
                    className="input-box"
                    style={{ backgroundColor: colors[index % colors.length] }}
                  />
                ))}
              </div>
            </div>
          )) : null
        }
        </div>
      )}
    </div>
  );
};

export default HomeOpen;

import React, { useState } from 'react';


const CreateEntry = () => {

    const apiUrl = "https://satta-backend.vercel.app";

    const [date, setDate] = useState('');
    const [values, setValues] = useState([]);

    const [day, setDay] = useState('');
    const [month, setMonth] = useState('');
    const [year, setYear] = useState('');

    const handleAddEntry = () => {
        if (day && month && year) {
            setValues([...values, [day, month, year]]);
            setDay('');
            setMonth('');
            setYear('');
        }
    };

    const handleSendData = async () => {
        // const dataToSend = {
        //     date: date,
        //     values: values,
        // };

        console.log({
            date: date,
            values: values,
        });
        // Send the data to the backend using the fetch API.
        try {
            let response = await fetch(`${apiUrl}/api/create/data`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    date: date,
                    values: values,
                })
            });
            if (response.ok) {
                response = await response.json();

                console.log('Data sent successfully!', response);
                alert(response.message)
              
            } else {
                console.error('Error sending data to the backend!', response);
            }
        } catch (error) {
            console.error('Error sending data to the backend!', error);
        }
    };


    return (
        <div style={{ fontFamily: 'Arial', padding: '20px', maxWidth: '400px', margin: 'auto' }}>
            <div>
                <h2>Create Entry</h2>
                <label>
                    Chart Heading:
                    <input
                        type="text"
                        value={date}
                        onChange={(e) => setDate(e.target.value)}
                        style={{ marginBottom: '10px', display: 'block', width: '100%', padding: '5px' }}
                    />
                </label>
            </div>
            <div>
                <h3>Add New Entry</h3>
                <label>
                    Value one:
                    <input
                        type="text"
                        value={day}
                        onChange={(e) => setDay(e.target.value)}
                        style={{ marginBottom: '10px', display: 'block', width: '100%', padding: '5px' }}
                    />
                </label>
                <label>
                    Value two:
                    <input
                        type="text"
                        value={month}
                        onChange={(e) => setMonth(e.target.value)}
                        style={{ marginBottom: '10px', display: 'block', width: '100%', padding: '5px' }}
                    />
                </label>
                <label>
                    Value three:
                    <input
                        type="text"
                        value={year}
                        onChange={(e) => setYear(e.target.value)}
                        style={{ marginBottom: '10px', display: 'block', width: '100%', padding: '5px' }}
                    />
                </label>
                <button
                    onClick={handleAddEntry}
                    style={{ padding: '10px', backgroundColor: '#4CAF50', color: 'white', border: 'none', cursor: 'pointer' }}
                >
                    Add Entry
                </button>
            </div>
            <div>
                <h3>Current Entries</h3>
                {values.map((value, index) => (
                    <div key={index} style={{ marginBottom: '5px' }}>
                        {value.map((v, i) => (
                            <span key={i} style={{ marginRight: '5px' }}>{v} </span>
                        ))}
                    </div>
                ))}
            </div>
            <button
                onClick={handleSendData}
                style={{ padding: '10px', backgroundColor: '#008CBA', color: 'white', border: 'none', cursor: 'pointer', marginTop: '20px' }}
            >
                Send Data to Backend
            </button>
        </div>
    );
};

export default CreateEntry;
